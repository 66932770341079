/******************************************************************

BatchGeo

Stylesheet: Globals

******************************************************************/

html {
	font-family: $sans-serif;
}

body {
	color: $black;
	font-family: $sans-serif;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}
img {
  height: auto;
  max-width: 100%;
  /* width: 100%; */
}
#mapDiv img {
	max-width: none;
}
a img {
  border: 0;
}
a, a:visited {
	color: $link-color;
	/* text-decoration: none; */
}
a:hover, a:active {
  /* border-bottom: 1px solid $link-hover; */
  color: $link-hover;
}
.round {
  @include border-radius(4px);
}
ul {
	list-style-type: disc;
}
ul, ol {
  margin: 9px 0 18px 1.2em;
}
li {
  margin: 0 0 9px 0;
}
p {
  margin-bottom: 18px;
}
h1 {
  font-size: 2.25rem;
  font-weight: 700;
	margin-bottom: 18px;
	line-height: 1.1;
}
h2 {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 1.5rem;
	line-height: 1.1;
}
h3 {
	font-size: 1.25rem;
	font-weight: 700;
	margin-top: 9px;
	margin-bottom: 18px;
	line-height: 1.1;
}
h4 {
	width: 20%;
	line-height: 2em;
	height: 20px;
	float: left;
	text-align: center;
	background-color: #369;
	padding: 0;
	margin: 0;
	border-width: 0;
	border-style: solid;
	border-color: $black;
}
h5 {
	font-size: 14px;
	margin-bottom: 6px;
	margin-top: 15px;
	border-width: 0 0 1px 0;
	border-color: #e5e5e5;
	border-style: solid;
	padding: 2px;
	color: #387cbc;
	font-weight: bold;
}

.h1 {
	font-size: 3.815rem;

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		font-size: 3.25rem;
	}

	@media screen and (min-width: 600px) and (max-width: 768px) {
		font-size: 2.75rem;
	}
	@media screen and (max-width: 599px) {
		font-size: 1.5rem;
	}
}

.h2 {
	font-size: 3.052rem;
	
	@media screen and (max-width: 1024px) {
		font-size: 2.75rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 2.25rem;
	}

	@media screen and (max-width: 599px) {
		font-size: 1.5rem;
	}
}

.h3 {
	font-size: 2.441rem;

	@media screen and (max-width: 1024px) {
		font-size: 2rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 1.75rem;
	}

	@media screen and (max-width: 599px) {
		font-size: 1.35rem;
	}
}

.h4 {
	font-size: 1.953rem;

	@media screen and (max-width: 1024px) {
		font-size: 1.75rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 1.5rem;
	}

	@media screen and (max-width: 599px) {
		font-size: 1.25rem;
	}
}
.h5 {
	font-size: 1.125rem;
}

hr {
  background: #eee;
  // background: -webkit-linear-gradient(-90deg, rgba(200,200,200,0) 0, rgba(255,255,255,0) 100%), -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0, rgba(0,0,0,0.2) 50%, rgba(255,255,255,0) 100%);
  border: 0;
  height:1px;
  margin:2em auto;
  width:100%;
}

.hr-gradient {
  height:40px;
  margin:0 auto;
  width:90%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: none;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  background: -webkit-linear-gradient(-90deg, rgba(200,200,200,0) 0, rgba(255,255,255,0) 100%), -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0, rgba(250,250,250,1) 50%, rgba(255,255,255,0) 100%);
  background: -moz-linear-gradient(180deg, rgba(250,250,250,0) 0, rgba(255,255,255,0) 100%), -moz-linear-gradient(90deg, rgba(255,255,255,0) 0, rgba(250,250,250,1) 50%, rgba(255,255,255,0) 100%);
  background: linear-gradient(180deg, rgba(250,250,250,0) 0, rgba(255,255,255,1) 100%), linear-gradient(90deg, rgba(255,255,255,0) 0, rgba(250,250,250,1) 50%, rgba(255,255,255,0) 100%);
  background-position: 50% 100%, 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
}

small {
    font-size: $small;
}

sup {
	font-size: 10px;
	position: relative;
	top: -1em;
}
.clearfix:before, .clearfix:after {
	content: "";
  display: table;
	zoom: 1; /* for IE */
}
.clearfix:after {
	clear: both;
}
.center {
  text-align: center;
}
.hide { 
  display: none; 
}
.italic {
	font-style: italic;
}

.right-arrow-link {
	padding-right: 1.5rem;
	background: url(/images/arrow-right.svg) no-repeat center right;
	border-bottom: transparent solid 1px;
	transition: all ease .2s;
}
.right-arrow-link.arrow-red {
	background-image: url(/images/arrow-right-red.svg);
}
a .right-arrow-link:hover,
a.right-arrow-link:hover {
	border-bottom: currentColor solid 1px;
}

.no-bottom-margin {
	margin-bottom: 0 !important;
}

/*
use the best ampersand
http://simplebits.com/notebook/2008/08/14/ampersands-2/
*/
span.amp {
  font-family: 'Andale Mono',Skia,'Trebuchet MS',sans-serif !important;
}
.second_btn {
	margin-left:10px;
}
/* @media screen and (min-width: 376px) {
	.second_btn {
		margin-left:10px;
	}
} */


